// Router
import { NavLink } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";
import { ROLE_TYPES, auditRoleList, isAudit } from "../../helpers/roleTypes";

//Styles
import "./SideNav.css";
import { RootState } from "../../redux/store";

const SideNav = ({ onCloseSideNav }: any) => {
  const { loggedInUser } = useSelector(
    (state: RootState) => state?.authReducer
  );
  const displaySubmenu = (id: string) => {
    const subMenuClassList =
      document.getElementById(id)?.children[1].classList!;

    if (!subMenuClassList.contains("sub-menu-visible")) {
      return subMenuClassList.add("sub-menu-visible");
    } else {
      return subMenuClassList.remove("sub-menu-visible");
    }
  };

  return (
    <div id="mySidebar" className="SideBar normal-1x SlideInLeft">
      <ul className="side-nav-links">
        {/* Dashboard */}
        {((loggedInUser?.roles &&
          loggedInUser?.roles?.length > 0 &&
          loggedInUser?.roles?.includes(ROLE_TYPES.GENERAL_DASHBOARD)) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(
              ROLE_TYPES.BUSINESS_PERFORMANCE_DASHBOARD
            ))) && (
          <div
            onClick={() => displaySubmenu("dashboard-menu")}
            id="dashboard-menu"
          >
            <li id="dashboard">
              <div className="parent-menu">
                <span className="link-icon mr-3">
                  <i className="ri-dashboard-line"></i>
                </span>
                <span className="menu-title">Dashboard</span>
                <i className="fa fa-angle-down fa-lg arrow-icon"></i>
              </div>
            </li>
            <div className="sub-menu-visible">
              {loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(ROLE_TYPES.GENERAL_DASHBOARD) && (
                  <div className="sub-menu-item">
                    <NavLink onClick={onCloseSideNav} to="/main-dashboard">
                      <span className="link-text">General</span>
                    </NavLink>
                  </div>
                )}

              {loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(
                  ROLE_TYPES.BUSINESS_PERFORMANCE_DASHBOARD
                ) && (
                  <div className="sub-menu-item">
                    <NavLink onClick={onCloseSideNav} to="/dashboard">
                      <span className="link-text">Business Performance</span>
                    </NavLink>
                  </div>
                )}
            </div>
          </div>
        )}

        {/* Loans */}
        {((loggedInUser?.roles &&
          loggedInUser?.roles?.length > 0 &&
          loggedInUser?.roles?.includes(ROLE_TYPES.CREDIT_VIEWER)) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(ROLE_TYPES.DISBURSEMENT_INITIATOR)) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(ROLE_TYPES.REPAYMENT_INITIATOR)) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(
              ROLE_TYPES.CREDIT_APPLICATION_APPROVER
            )) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(ROLE_TYPES.MCC)) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(ROLE_TYPES.DEAL_SLIP_APPROVER)) ||
          loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_PRODUCT_SETUP) ||
          loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_APPRAISAL) ||
          loggedInUser?.roles.includes(ROLE_TYPES.LEGAL_VIEWER)) && (
          <div onClick={() => displaySubmenu("loans-menu")} id="loans-menu">
            <li id="loans">
              <div className="parent-menu">
                <span className="link-icon mr-3">
                  <i className="ri-hand-coin-line"></i>
                </span>
                <span className="menu-title">Loans</span>
                <i className="fa fa-angle-down fa-lg arrow-icon"></i>
              </div>
            </li>

            <div className="sub-menu-visible">
              {((loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(ROLE_TYPES.CREDIT_VIEWER)) ||
                (loggedInUser?.roles &&
                  loggedInUser?.roles?.length > 0 &&
                  loggedInUser?.roles?.includes(
                    ROLE_TYPES.DISBURSEMENT_INITIATOR
                  ))) && (
                <div className="sub-menu-item">
                  <NavLink onClick={onCloseSideNav} to="/manage-loans">
                    <span className="link-text">Manage Loans</span>
                  </NavLink>
                </div>
              )}
              <div className="sub-menu-item">
                <NavLink
                  onClick={onCloseSideNav}
                  to="/credit-bureau-information"
                >
                  <span className="link-text">Send Credit Bureau Report</span>
                </NavLink>
              </div>

              {loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(
                  ROLE_TYPES.REPAYMENT_INITIATOR
                ) && (
                  <div className="sub-menu-item">
                    <NavLink onClick={onCloseSideNav} to="/loan-repayment">
                      <span className="link-text">Repayments</span>
                    </NavLink>
                  </div>
                )}
              <div className="sub-menu-item">
                <NavLink onClick={onCloseSideNav} to="/credit-bureau">
                  <span className="link-text">Credit Bureau</span>
                </NavLink>
              </div>
              {loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(
                  ROLE_TYPES.DEAL_SLIP_APPROVER
                ) && (
                  <div className="sub-menu-item">
                    <NavLink onClick={onCloseSideNav} to="/pending-slips">
                      <span className="link-text">Pending Deal Slips</span>
                    </NavLink>
                  </div>
                )}

              {loggedInUser?.roles.includes(
                ROLE_TYPES.CREDIT_PRODUCT_SETUP
              ) && (
                <div className="sub-menu-item">
                  <NavLink onClick={onCloseSideNav} to="/loan-product">
                    <span className="link-text">Loan Product Setup</span>
                  </NavLink>
                </div>
              )}

              {loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_RM_APPROVER) && (
                <div className="sub-menu-item">
                  <NavLink
                    onClick={onCloseSideNav}
                    to="/rm-pending-loan-application"
                  >
                    <span className="link-text">
                      RM Pending Loan Application
                    </span>
                  </NavLink>
                </div>
              )}

              {loggedInUser?.roles.includes(ROLE_TYPES.LEGAL_VIEWER) && (
                <div className="sub-menu-item">
                  <NavLink onClick={onCloseSideNav} to="/prepare-offer-letter">
                    <span className="link-text">Prepare Offer Letter</span>
                  </NavLink>
                </div>
              )}
              {loggedInUser?.roles.includes(
                ROLE_TYPES.CREDIT_APPLICATION_APPROVER
              ) && (
                <div className="sub-menu-item">
                  <NavLink
                    onClick={onCloseSideNav}
                    to="/loan-approver-pending-applications"
                  >
                    <span className="link-text">
                      Approver Pending Loan Application
                    </span>
                  </NavLink>
                </div>
              )}
              {loggedInUser?.roles.includes(ROLE_TYPES.MCC) && (
                <div className="sub-menu-item">
                  <NavLink
                    onClick={onCloseSideNav}
                    to="/MCC-pending-loan-applications"
                  >
                    <span className="link-text">
                      MCC Pending Loan Application
                    </span>
                  </NavLink>
                </div>
              )}
              {loggedInUser?.roles.includes(ROLE_TYPES.MCC_APPROVER) && (
                <div className="sub-menu-item">
                  <NavLink
                    onClick={onCloseSideNav}
                    to="/MCC-approvals-pending-loan-applications"
                  >
                    <span className="link-text">MCC Approvals</span>
                  </NavLink>
                </div>
              )}

              {loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_APPRAISAL) && (
                <div className="sub-menu-item">
                  <NavLink
                    onClick={onCloseSideNav}
                    to="/credit-analyst-pending-loan-application"
                  >
                    <span className="link-text">
                      CA Pending Loan Application
                    </span>
                  </NavLink>
                </div>
              )}
              {loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_APPRAISAL) && (
                <div className="sub-menu-item">
                  <NavLink
                    onClick={onCloseSideNav}
                    to="/predisbursement-pending-loan-application"
                  >
                    <span className="link-text">Pre-Disbursement Menu</span>
                  </NavLink>
                </div>
              )}

              <div className="sub-menu-item">
                <NavLink onClick={onCloseSideNav} to="/loan-applications">
                  <span className="link-text">Loan Applications</span>
                </NavLink>
              </div>

              <div className="sub-menu-item">
                <NavLink onClick={onCloseSideNav} to="/loan-eligibility">
                  <span className="link-text">Loan Eligibility Questions</span>
                </NavLink>
              </div>
            </div>
          </div>
        )}

        {/* Funding */}
        <div onClick={() => displaySubmenu("funding-menu")} id="funding-menu">
          <li id="loans">
            <div className="parent-menu">
              <span className="link-icon mr-3">
                <i className="ri-hand-coin-line"></i>
              </span>
              <span className="menu-title">Funding</span>
              <i className="fa fa-angle-down fa-lg arrow-icon"></i>
            </div>
          </li>

          <div className="sub-menu-visible">
            {loggedInUser?.roles &&
              loggedInUser?.roles.length > 0 &&
              (loggedInUser?.roles.includes(ROLE_TYPES.FUNDING_APPROVER) ||
                loggedInUser?.roles.includes(ROLE_TYPES.FUNDING_INITIATOR)) && (
                <div className="sub-menu-item">
                  <NavLink onClick={onCloseSideNav} to="/funding-wallets">
                    <span className="link-text">Funding Wallet</span>
                  </NavLink>
                </div>
              )}

            {loggedInUser?.roles &&
              loggedInUser?.roles?.length > 0 &&
              loggedInUser?.roles.includes(ROLE_TYPES.FUNDING_APPROVER) && (
                <div className="sub-menu-item">
                  <NavLink
                    onClick={onCloseSideNav}
                    to="/funding-request-approval"
                  >
                    <span className="link-text">Funding Request Approval</span>
                  </NavLink>
                </div>
              )}
          </div>
        </div>

        {/* Approvals */}
        {((loggedInUser?.roles &&
          loggedInUser?.roles?.length > 0 &&
          loggedInUser?.roles?.includes(ROLE_TYPES.DISBURSEMENT_APPROVER)) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(
              ROLE_TYPES.CUSTOMER_INVESTMENT_APPROVER
            )) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(ROLE_TYPES.REPAYMENT_APPROVER)) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(
              ROLE_TYPES.CREDIT_PRODUCT_APPROVE
            ))) && (
          <div
            onClick={() => displaySubmenu("approvals-menu")}
            id="approvals-menu"
          >
            <li id="approvals">
              <div className="parent-menu">
                <span className="link-icon mr-3">
                  <i className="ri-file-list-3-line"></i>
                </span>
                <span className="menu-title">Approvals</span>
                <i className="fa fa-angle-down fa-lg arrow-icon"></i>
              </div>
            </li>
            <div className="sub-menu-visible">
              {loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(
                  ROLE_TYPES.CREDIT_PRODUCT_APPROVE
                ) && (
                  <div className="sub-menu-item">
                    <NavLink onClick={onCloseSideNav} to="/manage-approvals">
                      <span className="link-text">Set up approvals</span>
                    </NavLink>
                  </div>
                )}
              {loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(ROLE_TYPES.ROLLOVER_APPROVER) && (
                  <div className="sub-menu-item">
                    <NavLink
                      onClick={onCloseSideNav}
                      to="/loan-rollover-request"
                    >
                      <span className="link-text">Loan Rollover</span>
                    </NavLink>
                  </div>
                )}

              {loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(
                  ROLE_TYPES.INVESTMENT_PRODUCT_VIEWER
                ) &&
                loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(
                  ROLE_TYPES.INVESTMENT_PRODUCT_APPROVER
                ) && (
                  <div className="sub-menu-item">
                    <NavLink onClick={onCloseSideNav} to="/approval-requests">
                      <span className="link-text">
                        Admin Investment Approvals
                      </span>
                    </NavLink>
                  </div>
                )}

              {loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(
                  ROLE_TYPES.DISBURSEMENT_APPROVER
                ) && (
                  <div className="sub-menu-item">
                    <NavLink onClick={onCloseSideNav} to="/approve-loans">
                      <span className="link-text">Loan Disbursement</span>
                    </NavLink>
                  </div>
                )}

              {loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(
                  ROLE_TYPES.REPAYMENT_APPROVER
                ) && (
                  <div className="sub-menu-item">
                    <NavLink onClick={onCloseSideNav} to="/repayment-request">
                      <span className="link-text">Loan Repayment</span>
                    </NavLink>
                  </div>
                )}

              {loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(
                  ROLE_TYPES.CUSTOMER_INVESTMENT_APPROVER
                ) && (
                  <div className="sub-menu-item">
                    <NavLink onClick={onCloseSideNav} to="/requests">
                      <span className="link-text">
                        Investor Approval Requests
                      </span>
                    </NavLink>
                  </div>
                )}
            </div>
          </div>
        )}
        {/* Wallet */}
        {((loggedInUser?.roles &&
          loggedInUser?.roles?.length > 0 &&
          loggedInUser?.roles?.includes(
            ROLE_TYPES.CUSTOMER_WALLET_INITIATOR
          )) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(ROLE_TYPES.CUSTOMER_WALLET_VIEWER)) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(
              ROLE_TYPES.AGGREGATOR_WALLET_DISBURSEMENT_VIEWER
            ))) && (
          <div onClick={() => displaySubmenu("wallet-menu")} id="wallet-menu">
            <li id="wallet">
              <div className="parent-menu">
                <span className="link-icon mr-3">
                  <i className="ri-wallet-line"></i>
                </span>
                <span className="menu-title">Wallet</span>
                <i className="fa fa-angle-down fa-lg arrow-icon"></i>
              </div>
            </li>
            <div className="sub-menu-visible">
              {((loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(
                  ROLE_TYPES.CUSTOMER_WALLET_INITIATOR
                )) ||
                (loggedInUser?.roles &&
                  loggedInUser?.roles?.length > 0 &&
                  loggedInUser?.roles?.includes(
                    ROLE_TYPES.CUSTOMER_WALLET_VIEWER
                  )) ||
                ROLE_TYPES.WALLET_PROVIDER) &&
                loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(ROLE_TYPES.AGGREGATOR_VIEWER) && (
                  <div className="sub-menu-item">
                    <NavLink onClick={onCloseSideNav} to="/wallet">
                      <span className="link-text">Wallet Provider</span>
                    </NavLink>
                  </div>
                )}

              {loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(
                  ROLE_TYPES.AGGREGATOR_WALLET_DISBURSEMENT_VIEWER
                ) &&
                loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(ROLE_TYPES.AGGREGATOR_VIEWER) && (
                  <div className="sub-menu-item">
                    <NavLink
                      onClick={onCloseSideNav}
                      to="/wallet-disbursement-transactions"
                    >
                      <span className="link-text">
                        Aggregator Wallet Transactions
                      </span>
                    </NavLink>
                  </div>
                )}

              {loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(
                  ROLE_TYPES.CUSTOMER_WALLET_VIEWER
                ) && (
                  <div className="sub-menu-item">
                    <NavLink onClick={onCloseSideNav} to="/company-wallets">
                      <span className="link-text">Company Wallets</span>
                    </NavLink>
                  </div>
                )}
            </div>
          </div>
        )}

        {/* Legal/Compliance Bank */}
        <div
          onClick={() => displaySubmenu("legal-and-compliance-menu")}
          id="legal-and-compliance-menu"
        >
          <li id="legal-and-compliance">
            <div className="parent-menu">
              <span className="link-icon mr-3">
                <i className="ri-line-chart-line"></i>
              </span>
              <span className="menu-title">Legal & Compliance</span>
              <i className="fa fa-angle-down fa-lg arrow-icon"></i>
            </div>
          </li>
          <div className="sub-menu-visible">
            <div className="sub-menu-item">
              <NavLink onClick={onCloseSideNav} to={`/customer-onboarding`}>
                <span className="link-text">Business Onboarding</span>
              </NavLink>
            </div>

            <div className="sub-menu-item">
              <NavLink
                onClick={onCloseSideNav}
                to="/signatories/pending-customers"
              >
                <span className="link-text">Signatories Approval</span>
              </NavLink>
            </div>
          </div>
        </div>

        {/* Digital Bank */}
        <div
          onClick={() => displaySubmenu("digital-bank-menu")}
          id="digital-bank-menu"
        >
          <li id="digital-bank">
            <div className="parent-menu">
              <span className="link-icon mr-3">
                <i className="ri-line-chart-line"></i>
              </span>
              <span className="menu-title">Customer</span>
              <i className="fa fa-angle-down fa-lg arrow-icon"></i>
            </div>
          </li>
          <div className="sub-menu-visible">
            <div className="sub-menu-item">
              <NavLink onClick={onCloseSideNav} to="/db/tier-upgrade">
                <span className="link-text">Tier Upgrade Requests</span>
              </NavLink>
            </div>

            <div className="sub-menu-item">
              <NavLink onClick={onCloseSideNav} to="/db/individual-customers">
                <span className="link-text">Individual Customers</span>
              </NavLink>
            </div>

            <div className="sub-menu-item">
              <NavLink onClick={onCloseSideNav} to="/db/business-customers">
                <span className="link-text">Business Customers</span>
              </NavLink>
            </div>
          </div>
        </div>

        {/* Investments */}
        {/* There should be another role to signup investor */}
        {((loggedInUser?.roles &&
          loggedInUser?.roles?.length > 0 &&
          loggedInUser?.roles?.includes(ROLE_TYPES.INVESTMENT_VIEWER)) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(
              ROLE_TYPES.INVESTMENT_PRODUCT_SETUP
            ))) &&
          loggedInUser?.roles &&
          loggedInUser?.roles?.length > 0 &&
          loggedInUser?.roles?.includes(ROLE_TYPES.AGGREGATOR_VIEWER) && (
            <div
              onClick={() => displaySubmenu("investment-menu")}
              id="investment-menu"
            >
              <li id="investment">
                <div className="parent-menu">
                  <span className="link-icon mr-3">
                    <i className="ri-line-chart-line"></i>
                  </span>
                  <span className="menu-title">Debt Investments</span>
                  <i className="fa fa-angle-down fa-lg arrow-icon"></i>
                </div>
              </li>
              <div className="sub-menu-visible">
                {loggedInUser?.roles &&
                  loggedInUser?.roles?.length > 0 &&
                  loggedInUser?.roles?.includes(
                    ROLE_TYPES.INVESTMENT_VIEWER
                  ) && (
                    <div className="sub-menu-item">
                      <NavLink
                        onClick={onCloseSideNav}
                        to="/investment-prospect"
                      >
                        <span className="link-text">Prospect</span>
                      </NavLink>
                    </div>
                  )}
                {loggedInUser?.roles &&
                  loggedInUser?.roles?.length > 0 &&
                  loggedInUser?.roles?.includes(
                    ROLE_TYPES.INVESTMENT_VIEWER
                  ) && (
                    <div className="sub-menu-item">
                      <NavLink onClick={onCloseSideNav} to="/investor">
                        <span className="link-text">Investors</span>
                      </NavLink>
                    </div>
                  )}

                {loggedInUser?.roles &&
                  loggedInUser?.roles?.length > 0 &&
                  loggedInUser?.roles?.includes(
                    ROLE_TYPES.INVESTMENT_VIEWER
                  ) && (
                    <div className="sub-menu-item">
                      <NavLink onClick={onCloseSideNav} to="/all-investments">
                        <span className="link-text">Investment Info</span>
                      </NavLink>
                    </div>
                  )}

                {((loggedInUser?.roles &&
                  loggedInUser?.roles?.length > 0 &&
                  loggedInUser?.roles?.includes(
                    ROLE_TYPES.INVESTMENT_PRODUCT_SETUP
                  )) ||
                  (loggedInUser?.roles &&
                    loggedInUser?.roles?.length > 0 &&
                    loggedInUser?.roles?.includes(
                      ROLE_TYPES.INVESTMENT_VIEWER
                    ) &&
                    loggedInUser?.loggedInUser?.roles.length > 0 &&
                    !isAudit(
                      [...loggedInUser?.loggedInUser?.roles],
                      [...auditRoleList]
                    ))) && (
                  <div className="sub-menu-item">
                    <NavLink onClick={onCloseSideNav} to="/investments">
                      <span className="link-text">Investment Funds</span>
                    </NavLink>
                  </div>
                )}

                {loggedInUser?.roles &&
                  loggedInUser?.roles?.length > 0 &&
                  loggedInUser?.roles?.includes(
                    ROLE_TYPES.INVESTMENT_VIEWER
                  ) && (
                    <div className="sub-menu-item">
                      <NavLink onClick={onCloseSideNav} to="/transactions">
                        <span className="link-text">
                          Investment Transactions
                        </span>
                      </NavLink>
                    </div>
                  )}
              </div>
            </div>
          )}

        {/* Aggregators */}
        {((loggedInUser?.roles &&
          loggedInUser?.roles?.length > 0 &&
          loggedInUser?.roles?.includes(
            ROLE_TYPES.AGGREGATOR_ONBOARDING_VIEWER
          )) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(ROLE_TYPES.AGGREGATOR_VIEWER)) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(ROLE_TYPES.CREDIT_PRODUCT_SETUP)) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(ROLE_TYPES.CREDIT_PRODUCT_VIEWER)) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(
              ROLE_TYPES.LEGAL_ONBOARDING_APPROVER
            )) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(
              ROLE_TYPES.CREDIT_ONBOARDING_APPROVER
            )) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(ROLE_TYPES.GLOBAL_WAITLIST))) &&
          loggedInUser?.roles &&
          loggedInUser?.roles?.length > 0 &&
          loggedInUser?.roles?.includes(ROLE_TYPES.AGGREGATOR_VIEWER) && (
            <div
              onClick={() => displaySubmenu("aggregator-menu")}
              id="aggregator-menu"
            >
              <li id="aggregator">
                <div className="parent-menu">
                  <span className="link-icon mr-3">
                    <i className="ri-group-line"></i>
                  </span>
                  <span className="menu-title">Aggregators</span>
                  <i className="fa fa-angle-down fa-lg arrow-icon"></i>
                </div>
              </li>
              <div className="sub-menu-visible">
                {loggedInUser?.roles &&
                  loggedInUser?.roles?.length > 0 &&
                  loggedInUser?.roles?.includes(
                    ROLE_TYPES.AGGREGATOR_VIEWER
                  ) && (
                    <div className="sub-menu-item">
                      <NavLink onClick={onCloseSideNav} to="/manage-ecosystems">
                        <span className="link-text">Manage Aggregators</span>
                      </NavLink>
                    </div>
                  )}

                {/* {loggedInUser?.roles &&
                  loggedInUser?.roles?.length > 0 &&
                  loggedInUser?.roles?.includes(
                    ROLE_TYPES.AGGREGATOR_ONBOARDING_VIEWER
                  ) && (
                    <div className="sub-menu-item">
                      <NavLink
                        onClick={onCloseSideNav}
                        to={`/customer-onboarding?sourceType=${SourceType.SPARK}`}
                      >
                        <span className="link-text">Onboarding</span>
                      </NavLink>
                    </div>
                  )} */}

                {loggedInUser?.roles &&
                  loggedInUser?.roles?.length > 0 &&
                  loggedInUser?.roles?.includes(ROLE_TYPES.GLOBAL_WAITLIST) && (
                    <div className="sub-menu-item">
                      <NavLink
                        onClick={onCloseSideNav}
                        to="/aggregator-global-waitlist"
                      >
                        <span className="link-text">Global Waitlist</span>
                      </NavLink>
                    </div>
                  )}
              </div>
            </div>
          )}
        {/* Set Up */}
        {((loggedInUser?.roles &&
          loggedInUser?.roles?.length > 0 &&
          loggedInUser?.roles?.includes(ROLE_TYPES.USER_CREATION_INITIATOR)) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(ROLE_TYPES.CREDIT_PRODUCT_VIEWER) &&
            loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(ROLE_TYPES.CREDIT_PRODUCT_SETUP)) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(ROLE_TYPES.GLOBAL_SETTINGS))) &&
          loggedInUser?.roles &&
          loggedInUser?.roles?.length > 0 &&
          loggedInUser?.roles?.includes(ROLE_TYPES.AGGREGATOR_VIEWER) && (
            <div onClick={() => displaySubmenu("setup-menu")} id="setup-menu">
              <li id="setup">
                <div className="parent-menu">
                  <span className="link-icon mr-3">
                    <i className="ri-settings-2-line"></i>
                  </span>
                  <span className="menu-title">Setup</span>
                  <i className="fa fa-angle-down fa-lg arrow-icon"></i>
                </div>
              </li>

              <div className="sub-menu-visible">
                {loggedInUser?.roles &&
                  loggedInUser?.roles?.length > 0 &&
                  loggedInUser?.roles?.includes(
                    ROLE_TYPES.USER_CREATION_INITIATOR
                  ) && (
                    <div className="sub-menu-item">
                      <NavLink
                        onClick={onCloseSideNav}
                        to="/manage-roles-and-users"
                      >
                        <span className="link-text">User Management</span>
                      </NavLink>
                    </div>
                  )}

                {loggedInUser?.roles &&
                  loggedInUser?.roles?.length > 0 &&
                  loggedInUser?.roles?.includes(ROLE_TYPES.GLOBAL_SETTINGS) && (
                    <div className="sub-menu-item">
                      <NavLink onClick={onCloseSideNav} to="/settings">
                        <span className="link-text">Settings</span>
                      </NavLink>
                    </div>
                  )}

                {loggedInUser?.roles &&
                  loggedInUser?.roles?.length > 0 &&
                  loggedInUser?.roles?.includes(ROLE_TYPES.GLOBAL_SETTINGS) && (
                    <div className="sub-menu-item">
                      <NavLink onClick={onCloseSideNav} to="/bank-statement">
                        <span className="link-text">Bank Statement</span>
                      </NavLink>
                    </div>
                  )}

                {loggedInUser?.roles &&
                  loggedInUser?.roles?.length > 0 &&
                  loggedInUser?.roles?.includes(
                    ROLE_TYPES.CREDIT_PRODUCT_SETUP
                  ) &&
                  loggedInUser?.roles &&
                  loggedInUser?.roles?.length > 0 &&
                  loggedInUser?.roles?.includes(
                    ROLE_TYPES.CREDIT_PRODUCT_VIEWER
                  ) &&
                  loggedInUser?.roles &&
                  loggedInUser?.roles?.length > 0 &&
                  loggedInUser?.roles?.includes(
                    ROLE_TYPES.AGGREGATOR_VIEWER
                  ) && (
                    <div className="sub-menu-item">
                      <NavLink onClick={onCloseSideNav} to="/product-setup">
                        <span className="link-text">Embed Product Setup</span>
                      </NavLink>
                    </div>
                  )}
                <div className="sub-menu-item">
                  <NavLink onClick={onCloseSideNav} to="/queue">
                    <span className="link-text">Queue Error Bucket</span>
                  </NavLink>
                </div>

                {loggedInUser?.roles.includes(ROLE_TYPES.CREDIT_APPRAISAL) && (
                  <div className="sub-menu-item">
                    <NavLink
                      onClick={onCloseSideNav}
                      to="/credit-analysis-settings"
                    >
                      <span className="link-text">Credit Appraisal Setup</span>
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
          )}
        {/* Reports */}
        {((loggedInUser?.roles &&
          loggedInUser?.roles?.length > 0 &&
          loggedInUser?.roles?.includes(ROLE_TYPES.STANDARD_LOAN_REPORT)) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(ROLE_TYPES.LOAN_REPAYMENT_REPORT)) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(ROLE_TYPES.AUDIT_TRAIL))) && (
          <div onClick={() => displaySubmenu("report-menu")} id="report-menu">
            <li id="report">
              <div className="parent-menu">
                <span className="link-icon mr-3">
                  <i className="ri-file-list-line"></i>
                </span>
                <span className="menu-title">Reports</span>
                <i className="fa fa-angle-down fa-lg arrow-icon"></i>
              </div>
            </li>
            <div className="sub-menu-visible">
              <div className="sub-menu-item">
                <NavLink onClick={onCloseSideNav} to="/main-report">
                  <span className="link-text">Standard Loan Report</span>
                </NavLink>
              </div>

              {loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(
                  ROLE_TYPES.LOAN_REPAYMENT_REPORT
                ) && (
                  <div className="sub-menu-item">
                    <NavLink
                      onClick={onCloseSideNav}
                      to="/loan-repayment-reports"
                    >
                      <span className="link-text">Loan Repayment Report</span>
                    </NavLink>
                  </div>
                )}

              {loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(
                  ROLE_TYPES.STANDARD_LOAN_REPORT
                ) && (
                  <div className="sub-menu-item">
                    <NavLink
                      onClick={onCloseSideNav}
                      to="/loan-income-interest-reports"
                    >
                      <span className="link-text">Income Interest Report</span>
                    </NavLink>
                  </div>
                )}

              {loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(ROLE_TYPES.AUDIT_TRAIL) && (
                  <div className="sub-menu-item">
                    <NavLink onClick={onCloseSideNav} to="/audit-trail">
                      <span className="link-text">Audit/Trail</span>
                    </NavLink>
                  </div>
                )}

              {loggedInUser?.roles &&
                loggedInUser?.roles?.length > 0 &&
                loggedInUser?.roles?.includes(ROLE_TYPES.REGULATORY_REPORT) && (
                  <>
                    <div className="sub-menu-item">
                      <NavLink onClick={onCloseSideNav} to="/regulatory">
                        <span className="link-text">Regulatory</span>
                      </NavLink>
                    </div>

                    <div className="sub-menu-item">
                      <NavLink onClick={onCloseSideNav} to="/finance">
                        <span className="link-text">Finance</span>
                      </NavLink>
                    </div>
                  </>
                )}
            </div>
          </div>
        )}

        {/* Messaging */}
        {((loggedInUser?.roles &&
          loggedInUser?.roles?.length > 0 &&
          loggedInUser?.roles?.includes(ROLE_TYPES.NOTIFICATION_SETUP)) ||
          (loggedInUser?.roles &&
            loggedInUser?.roles?.length > 0 &&
            loggedInUser?.roles?.includes(ROLE_TYPES.NOTIFICATION_VIEWER))) && (
          <div
            onClick={() => displaySubmenu("messaging-menu")}
            id="messaging-menu"
          >
            <li id="messaging">
              <div className="parent-menu">
                <span className="link-icon mr-3">
                  <i className="ri-global-line"></i>
                </span>
                <span className="menu-title">Messaging</span>
                <i className="fa fa-angle-down fa-lg arrow-icon"></i>
              </div>
            </li>
            <div className="sub-menu-visible">
              <div className="sub-menu-item">
                <NavLink onClick={onCloseSideNav} to="/messaging">
                  <span className="link-text">Send Notification</span>
                </NavLink>
              </div>

              <div className="sub-menu-item">
                <NavLink onClick={onCloseSideNav} to="/birthday-notifications">
                  <span className="link-text">Birthday Notifications</span>
                </NavLink>
              </div>
            </div>
          </div>
        )}
      </ul>
    </div>
  );
};

export default SideNav;
