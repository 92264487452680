import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MDBTableHead, MDBTable, MDBTableBody } from "mdbreact";
import { Loader } from "../../../UI/Loaders/Loaders";
import { appInsights } from "../../../config/appInsights";
import Paginator from "../../Pagination/Pagination.component";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import { showModal } from "../../../redux/actions/modal";
import { RootState } from "../../../redux/store";
import { setAdvancedCustomerSearchParams } from "../../../redux/actions/digitalBank";
import moment from "moment";
import { usePendingSignatoryCustomers } from "../../../custom-hooks/usePendingSignatoryUsers";
import { attachClassNameToTag } from "../../../helpers/attachClassNameToTag";

const SignatoryCustomers = () => {
  const [query, setQuery] = useState({
    searchQuery: "",
    page: 1,
    pageSize: 10,
    searchValue: "",
    startDate: "",
    endDate: "",
  });
  const [state, setState] = useState({
    searchQuery: "",
    page: 1,
    pageSize: 10,
    searchValue: "",
    startDate: "",
    endDate: "",
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const searchParams = useSelector(
    (state: RootState) => state?.digitalBankReducer
  );

  useEffect(() => {
    appInsights.trackPageView({
      name: "SignatorySetup/Customers",
      isLoggedIn: true,
    });
  }, []);

  const {
    data: usersPendingApproval,
    status,
    error,
  } = usePendingSignatoryCustomers({
    page: query?.page,
    pageSize: query?.pageSize,
    endDate: searchParams?.endDate ? searchParams?.endDate : query?.endDate,
    name: searchParams?.name!,
    phoneNumber: searchParams?.phoneNumber!,
    startDate: searchParams?.startDate
      ? searchParams?.startDate
      : query?.startDate,
  });

  let RenderedComponent = null;
  if (!usersPendingApproval && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Pending Users
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Pending Customers..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Pending Customers, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (usersPendingApproval &&
        usersPendingApproval.data &&
        usersPendingApproval.data.length === 0) ||
      (usersPendingApproval && !usersPendingApproval.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no Pending
              Customers
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          <MDBTable
            responsive
            minimal="true"
            striped={false}
            bordered
            hover
            id="table-to-xls"
          >
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>BVN</th>
                <th>Status</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {usersPendingApproval &&
                usersPendingApproval.data.map((data, _id: number) => {
                  const {
                    batchReferenceNumber,
                    bvn,
                    createdAt,
                    currentApprovedAmount,
                    email,
                    firstName,
                    lastName,
                    phoneNumber,
                    status,
                  } = data;
                  return (
                    <tr key={_id}>
                      <td>
                        {" "}
                        {(query?.page - 1) * query?.pageSize + (_id + 1)}
                      </td>
                      <td>{firstName}</td>
                      <td>{lastName}</td>
                      <td>{phoneNumber}</td>
                      <td>{email}</td>
                      <td>{bvn}</td>
                      <td>{status}</td>
                      <td>
                        {createdAt
                          ? moment(createdAt).format("DD-MM-YYYY")
                          : ""}
                      </td>

                      <td>
                        <span
                          className="color-mid-blue cursor-pointer font-weight-bold"
                          onClick={() => {
                            history.push({
                              pathname: `/signatories/pending-customers/${data?.id}`,
                              state: data,
                            });
                          }}
                        >
                          {" "}
                          View Details
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
        </>
      );
    }
  }

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setState((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const filterData = () => {
    setQuery(state);
  };

  const handleClear = () => {
    setQuery({
      searchQuery: "",
      page: 1,
      pageSize: 10,
      searchValue: "",
      startDate: "",
      endDate: "",
    });
    dispatch(
      setAdvancedCustomerSearchParams({
        email: "",
        name: "",
        phoneNumber: "",
        accountNumber: "",
        rcNumber: "",
        businessType: "",
        endDate: "",
        startDate: "",
      })
    );
  };

  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x animated fadeInRight">
        <h3 className="page-title mb-4">Pending Users</h3>

        <div className="row">
          <div className="form-group col-md-2">
            <label>From</label>
            <div className="input-group">
              <input
                type="date"
                name="startDate"
                className="form-control"
                value={state.startDate}
                onChange={handleFilterChange}
              />
            </div>
          </div>
          <div className="form-group col-md-2">
            <label>To</label>
            <div className="input-group">
              <input
                type="date"
                className="form-control"
                name="endDate"
                value={state.endDate}
                onChange={handleFilterChange}
              />
            </div>
          </div>
          <div className="form-group col-md-2" style={{ marginTop: "30px" }}>
            <div className="input-group">
              <CustomSelectDropdown
                name="aggregatorName"
                onChange={() => console.log()}
                isShowDropdownArrow
              >
                <option value={10}>10 Entries</option>
                <option value={20}>20 Entries</option>
                <option value={50}>50 Entries</option>
                <option value={100}>100 Entries</option>
              </CustomSelectDropdown>
            </div>
          </div>
          {/* <div className="form-group col-md-2" style={{ marginTop: "30px" }}>
            <div className="input-group">
              <button
                className="btn advancly-white-btn ml-2 form-control"
                onClick={(e) => {
                  dispatch(
                    showModal({
                      modalIdentifier: "advancedcustomersearch",
                      dataRecord: { customerType: "businessCustomers" },
                    })
                  );
                }}
              >
                <i className="fas fa-search" />
                &nbsp; Advance Search
              </button>
            </div>
          </div> */}
          <div className="form-group col-md-2" style={{ marginTop: "30px" }}>
            <div className="input-group">
              <button
                className="btn advancly-btn ml-2 form-control"
                onClick={filterData}
              >
                Search
              </button>
            </div>
          </div>
          <div className="form-group col-md-2" style={{ marginTop: "30px" }}>
            <div className="input-group">
              <button
                type="submit"
                className={`btn advancly-red-btn btn-md ml-3`}
                style={{ height: "3rem" }}
                onClick={handleClear}
              >
                Clear
              </button>
            </div>
          </div>
        </div>

        <div>{RenderedComponent}</div>

        <Paginator
          size={query.pageSize}
          page={query.page}
          count={usersPendingApproval?.totalCount}
          changeCurrentPage={(pageNumber: number) =>
            setQuery({ ...state, page: pageNumber })
          }
        />
      </div>
    </div>
  );
};

export default SignatoryCustomers;
