import { Fragment, useState, useEffect } from "react";

import BackButtonComponent from "../../NewComponents/BackButton/BackButton.component";
import ToggleTab from "./../../NewComponents/ToggleTab/ToggleTab.component";
import { useLocation, useHistory } from "react-router-dom";
import { postData } from "../../newApis/apiMethods";
import * as ajaxEndpoints from "../../api/ajax-endpoints";
import { errorHandler } from "./../../helpers/errorHandler";
import Alert from "../../NewComponents/TypedComponents/Alert/Alert.component";
import { appInsights } from "../../config/appInsights";
import { IPendingCustomerApproval } from "../../custom-hooks/usePendingSignatoryUsers";
import ApproverDetails from "./ApproverDetails";

import styles from "./style.module.scss";

enum ApprovalStatus {
  APPROVE = 1,
  DECLINE = 3,
}

const PendingUserApproval = () => {
  const [activeTab, setActiveTab] = useState("approver-details");
  const [actionType, setActionType] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const { state } = useLocation();

  const {
    batchReferenceNumber,
    bvn,
    createdAt,
    currentApprovedAmount,
    currentRoles,
    customerUserApproverDetails,
    email,
    firstName,
    id,
    initiatedBy,
    lastName,
    phoneNumber,
    requestedApprovedAmount,
    rolesRequestedForApproval,
    status,
    updatedAt,
  } = state as IPendingCustomerApproval;

  const history = useHistory();

  useEffect(() => {
    appInsights.trackPageView({
      name: "PendingUserApproval.tsx",
      isLoggedIn: true,
    });
  }, []);

  const handleApproveDeclineCustomer = async (type: "approve" | "decline") => {
    setLoading(true);

    const reqBody = {
      CustomerUserId: id,
      ApprovalStatus:
        type === "approve" ? ApprovalStatus.APPROVE : ApprovalStatus.DECLINE,
      BatchReferenceNumber: batchReferenceNumber,
    };

    try {
      const response = await postData(
        ajaxEndpoints.DB_ADMIN_USER_APPROVAL,
        reqBody
      );

      setSuccess(response?.message);

      setTimeout(() => {
        history.goBack();
      }, 2000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "PendingUserApproval.tsx" },
      });

      setError(errorHandler(error));
    }

    setLoading(false);
  };

  return (
    <Fragment>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> Review Request
        </h3>
        <div className="d-flex align-items-center justify-content-end">
          <div>
            <button
              className="btn advancly-red-btn btn-md mr-2"
              onClick={() => {
                setActionType("decline");
                handleApproveDeclineCustomer("decline");
              }}
            >
              {loading && actionType === "decline" ? "Declining..." : "Decline"}
            </button>
            <button
              className="btn advancly-btn btn-md"
              onClick={() => {
                setActionType("approve");
                handleApproveDeclineCustomer("approve");
              }}
            >
              {loading && actionType === "approve"
                ? "Approving..."
                : "Approve Request"}
            </button>
          </div>
        </div>
      </div>
      <div className={styles.profileArea}>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>First Name:</div>
          <div className={styles.content}>{firstName}</div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Last Name</div>
          <div className={styles.content}>{lastName}</div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Phone Number:</div>
          <div className={styles.content}>{phoneNumber}</div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>BVN:</div>
          <div className={styles.content}>{bvn}</div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Email</div>
          <div className={styles.content}>{email}</div>
        </div>
        <div className={styles.profileAreaCard}>
          <div className={styles.title}>Status</div>
          <div className={styles.content}>{status}</div>
        </div>
      </div>

      <div className="alignToggleTabItems mb-3">
        <ToggleTab
          text="Customer"
          id="approver-details"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>

      <div>
        {(() => {
          switch (activeTab) {
            case "approver-details":
              return (
                <ApproverDetails
                  data={{
                    approverDetails: customerUserApproverDetails!,
                    rolesRequestedForApproval: rolesRequestedForApproval!,
                    requestedApprovedAmount: requestedApprovedAmount!,
                  }}
                />
              );

            default:
              return null;
          }
        })()}
      </div>
    </Fragment>
  );
};

export default PendingUserApproval;
