import { useQuery } from "react-query";
import { getData } from "../newApis/dotNetApiMethods";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { USE_MOCK } from "../appConstants";
import { IPaginatedResponse } from "../interfaces/response";

export interface IPendingApprovalParams {
  page: number;
  pageSize: number;
  email?: string;
  phoneNumber?: string;
  bvn?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
  name?: string;
}

export type TCustomerApproverDetails = {
  funderPeopleId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  funderPersonType: string;
  status: string;
};

export interface IPendingCustomerApproval {
  id: number;
  bvn: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  initiatedBy: {
    userId: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  currentApprovedAmount: number | null;
  batchReferenceNumber: string | null;
  requestedApprovedAmount: number | null;
  currentRoles: string[];
  rolesRequestedForApproval: string[] | null;
  customerUserApproverDetails: TCustomerApproverDetails[];
  status: string;
  createdAt: string;
  updatedAt: string | null;
}

export const getPendingUsersForApproval = async ({
  page,
  pageSize,
  startDate,
  endDate,
  bvn,
  email,
  name,
  phoneNumber,
}: IPendingApprovalParams): Promise<
  IPaginatedResponse<IPendingCustomerApproval[]>
> => {
  if (USE_MOCK) {
    // Return mock data using setTimeout to simulate network delay
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          message: "Requests fetched successfully!",
          status: true,
          status_code: 200,
          page: 1,
          pageSize: 10,
          totalCount: 100,
          totalPages: 100,
          data: [
            {
              id: 8,
              bvn: "37283941111",
              firstName: "Jacob",
              lastName: "Hormes",
              email: "jacobhormes@yopmail.com",
              phoneNumber: "09052784736",
              initiatedBy: {
                userId: 2285,
                firstName: "Ahmed",
                lastName: "Olise",
                email: "ahmed.olise@yopmail.com",
                phoneNumber: "08127077683",
              },
              currentApprovedAmount: null,
              batchReferenceNumber: null,
              requestedApprovedAmount: null,
              currentRoles: ["customer_business_initiator"],
              rolesRequestedForApproval: null,
              customerUserApproverDetails: [],
              status: "PendingBankApproval",
              createdAt: "2024-11-25T07:50:32.338506",
              updatedAt: null,
            },
            {
              id: 13,
              bvn: "22174943639",
              firstName: "Caleb",
              lastName: "Omoijuanfo",
              email: "c.omoijuanfo@gmail.com",
              phoneNumber: "08127077683",
              initiatedBy: {
                userId: 2285,
                firstName: "Ahmed",
                lastName: "Olise",
                email: "ahmed.olise@yopmail.com",
                phoneNumber: "08127077683",
              },
              currentApprovedAmount: null,
              batchReferenceNumber: null,
              requestedApprovedAmount: null,
              currentRoles: ["customer_business_viewer"],
              rolesRequestedForApproval: null,
              customerUserApproverDetails: [],
              status: "PendingBankApproval",
              createdAt: "2024-11-25T15:50:43.6",
              updatedAt: null,
            },
          ],
        });
      }, 5000);
    });
  }

  let queryParams = `?Page=${page}&PageSize=${pageSize}`;
  if (startDate) queryParams += `&startDate=${startDate}`;
  if (endDate) queryParams += `&endDate=${endDate}`;
  if (email) queryParams += `&email=${email}`;
  if (bvn) queryParams += `&bvn=${bvn}`;
  if (name) queryParams += `&name=${name}`;
  if (phoneNumber) queryParams += `&phoneNumber=${phoneNumber}`;

  const { data } = await getData(
    `${ajaxEndpoints.DB_SIGNATORY_USERS_PENDING_APPROVAL}${queryParams}`
  );

  return data;
};

export const usePendingSignatoryCustomers = (
  params: IPendingApprovalParams
) => {
  return useQuery<IPaginatedResponse<IPendingCustomerApproval[]>, Error>(
    ["getPendingUsersForApproval", params],
    () => getPendingUsersForApproval(params),
    {
      refetchOnWindowFocus: false,
    }
  );
};
